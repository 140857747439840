<script setup>
  const { label, to } = defineProps({
    label: String,
    to: String,
    delay: { type: Number, default: 0.0 },
  });

  // --

  const border = ref();
  const text = ref();

  const hover = ref(false);

  const blink = ref(false);

  // --

  onMounted(() => {
    border.value.show();
  });

  function onMouseEnter() {
    text.value.show(null, () => {}, 0.0);
    hover.value = true;
  }

  function onMouseLeave() {
    border.value.show();
    hover.value = false;
  }

  // --

  defineExpose({ onMouseEnter, onMouseLeave });
</script>

<!----------------------------------------------------------------------------->

<template>
  <a
    :href="to"
    :target="useLinkTarget(to)"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    :class="{ hover }"
  >
    <Border ref="border"
      ><span class="inner"
        ><Scramble
          ref="text"
          :text="label"
          class="label"
          :delay="delay"
          :class="{ blink }" /></span
    ></Border>
  </a>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    @include hitbox;

    @include hover {
      .inner {
        background-color: $white;
        color: $black;

        transition-duration: 0.03s;
        transition-delay: 0s;
      }
    }

    .inner {
      position: relative;
      z-index: 1;

      display: block;
      padding: px(4) px(15) px(4.5);

      transition: background-color 0.08s $sineInOut;

      display: flex;
      justify-content: center;

      @include break-medium {
        padding: px(3) px(12) px(3);
      }
    }
  }
</style>
