<script setup>
  import gsap from 'gsap';
  import { smoothie } from '~/composables/pinkyellow/useSmoothie';

  onServerPrefetch(async () => {
    const baseStore = useBaseStore();
    await baseStore.fetchLayout();
  });

  // --

  useTouchDevice();
  useSweet16(19.2, 25.0);

  // --

  useSmoothie({
    flavour: (t) => 1.0 - Math.pow(2.0, -10.0 * t), // easing

    slurp: 1.17, // scroll duration in seconds
    limit: 120.0, // maximum amount of pixels per slurp

    wheel: 1.0,
    touch: 0.0,
    slide: 0.0,
  });

  // --

  const scrollPosition = ref(0.0);
  const scrollProgress = ref(0.0);
  const scrollDirection = ref(1.0);
  const scrollSpeed = ref(0.0);

  onBeforeMount(() => {
    gsap.ticker.fps(60.0);
  });

  onMounted(() => {
    requestAnimationFrame(() => window.scrollTo(0.0, 0.0));
  });

  // --

  onScroll((_scrollX, scrollY) => {
    scrollDirection.value = scrollPosition.value <= scrollY ? 1.0 : -1.0;

    scrollSpeed.value = scrollY - scrollPosition.value;
    scrollPosition.value = scrollY;

    scrollProgress.value = smoothie.value.progress;
  });

  provide('scrollPosition', scrollPosition);
  provide('scrollProgress', scrollProgress);
  provide('scrollDirection', scrollDirection);
  provide('scrollSpeed', scrollSpeed);

  // --

  const { loaderFinished } = useLoader();
</script>

<!----------------------------------------------------------------------------->

<template>
  <Pillow />
  <Html translate="no" />

  <Title>Anima</Title>

  <!--  <Fonts />-->

  <Body>
    <Loader v-if="!loaderFinished" @finished="loaderFinished = true" />
    <Header />
    <HeaderMobile />
    <main>
      <NuxtPage />
      <Anima />
    </main>
    <SignupOverlay />
    <Footer />
  </Body>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  main {
    min-height: 100vh;
  }
</style>
