<script setup>
  import gsap from 'gsap';

  // --

  const { thin } = defineProps({ thin: Boolean, orange: Boolean });

  // --

  const border = ref();
  let lines = [];

  onMounted(() => {
    lines = border.value.querySelectorAll('.line');
  });

  // --

  const progress = ref(0.0);

  function show(delay = 0.0) {
    lines[0].style.transformOrigin = 'left top';
    lines[1].style.transformOrigin = 'right top';
    lines[2].style.transformOrigin = 'right bottom';
    lines[3].style.transformOrigin = 'left bottom';

    gsap.killTweensOf(progress);
    gsap.fromTo(
      progress,
      { value: 0.0 },
      {
        value: 1.0,
        delay,
        duration: 0.88,
        ease: 'quad.inOut',
        onUpdate: () => {
          lines[0].style.transform = `scale3d(${Math.min(
            progress.value * 4.0,
            1.0,
          )}, 1.0, 1.0)`;
          lines[1].style.transform = `scale3d(1.0, ${Math.min(
            Math.max(progress.value * 4.0 - 1.0, 0.0),
            1.0,
          )}, 1.0)`;
          lines[2].style.transform = `scale3d(${Math.min(
            Math.max(progress.value * 4.0 - 2.0, 0.0),
            1.0,
          )}, 1.0, 1.0)`;
          lines[3].style.transform = `scale3d(1.0, ${Math.min(
            Math.max(progress.value * 4.0 - 3.0, 0.0),
            1.0,
          )}, 1.0)`;
        },
      },
    );
  }

  function hide(delay) {
    lines[0].style.transformOrigin = 'right top';
    lines[1].style.transformOrigin = 'right bottom';
    lines[2].style.transformOrigin = 'left bottom';
    lines[3].style.transformOrigin = 'left top';

    gsap.killTweensOf(progress);
    gsap.fromTo(
      progress,
      { value: 0.0 },
      {
        value: 1.0,
        delay,
        duration: 0.63,
        ease: 'quint.inOut',
        onUpdate: () => {
          lines[0].style.transform = `scale3d(${Math.min(
            Math.max(1.0 - progress.value * 4.0, 0.0),
            1.0,
          )}, 1.0, 1.0)`;
          lines[1].style.transform = `scale3d(1.0, ${Math.min(
            Math.max(2.0 - progress.value * 4.0, 0.0),
            1.0,
          )}, 1.0)`;
          lines[2].style.transform = `scale3d(${Math.min(
            Math.max(3.0 - progress.value * 4.0, 0.0),
            1.0,
          )}, 1.0, 1.0)`;
          lines[3].style.transform = `scale3d(1.0, ${Math.min(
            Math.max(4.0 - progress.value * 4.0, 0.0),
            1.0,
          )}, 1.0)`;
        },
      },
    );
  }

  // --

  defineExpose({ show, hide });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="border" class="border" :class="{ thin, orange }">
    <span class="line" />
    <span class="line" />
    <span class="line" />
    <span class="line" />
    <slot />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .border {
    position: relative;

    span {
      position: absolute;

      width: px(1);
      height: px(1);

      background-color: $white;

      transition: background-color 0.44s $sineInOut;

      &:nth-child(1) {
        width: 100% !important;

        top: 0;
        left: 0;

        transform: scale3d(0, 1, 1);
      }

      &:nth-child(2) {
        height: 100% !important;

        top: 0;
        right: 0;

        transform: scale3d(1, 0, 1);
      }

      &:nth-child(3) {
        width: 100% !important;

        bottom: 0;
        left: 0;

        transform: scale3d(0, 1, 1);
      }

      &:nth-child(4) {
        height: 100% !important;

        top: 0;
        left: 0;

        transform: scale3d(1, 0, 1);
      }
    }

    &.thin {
      span {
        width: px(1);
        height: px(1);

        background-color: $white-20;
      }
    }

    &.orange {
      span {
        background-color: $orange;
      }
    }
  }
</style>
