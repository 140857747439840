export default (element) => {
  const visible = ref(false);

  useTelescope(element, {
    visible: () => {
      visible.value = true;
    },
    partial: () => {
      visible.value = true;
    },
    hidden: () => {
      visible.value = false;
    },
  });

  return visible;
};
