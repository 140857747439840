<script setup>
  import Anima from './core/anima';

  // --

  const { loaderProgress, loaderComplete } = useLoader();
  const DOMLock = useDOMLock();

  // --

  let anima;
  const animaEl = ref();

  onMounted(() => {
    anima = new Anima(animaEl.value, {
      path: useBaseStore().getCdnBaseUrl + '/static',
      isTouch: useDevice().isMobileOrTablet,

      onLoadProgress: (progress) => {
        loaderProgress.value = progress;
      },
      onLoadComplete: () => {
        loaderComplete.value = true;
      },

      onCursorDown: () => {
        DOMLock.value = true;
      },

      onCursorUp: () => {
        DOMLock.value = false;
      },
    });
  });

  // --

  // const scrollPosition = inject('scrollPosition');
  // const scrollProgress = inject('scrollProgress');
  // const scrollDirection = inject('scrollDirection');
  // const scrollSpeed = inject('scrollSpeed');

  onResize((width, height, pixelRatio) => {
    anima.resize(width, height, pixelRatio);
  });

  // watch(scrollPosition, (position) => {
  //   anima.scroll(position, scrollProgress.value, scrollDirection.value, scrollSpeed.value);
  // });

  // --

  onTick((dt) => {
    anima.update(dt);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="animaEl" class="anima" :class="DOMLock"></div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .anima {
    position: fixed;

    top: 0;
    left: 0;

    // z-index: $layer-back;

    &.DOMLock {
      cursor: none;
    }
  }
</style>
