import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: '6LekK5wpAAAAAFhvOOxbDnfmTj4_GbNWJqTvZ_Jh',
    loaderOptions: {
      autoHideBadge: true,
    },
  });
});
