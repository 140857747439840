<script setup>
  import { mod } from '@pinkyellow/pillow';

  // --

  const props = defineProps(['src', 'cols', 'rows', 'frames', 'position']);

  const { src, cols, rows, frames } = props;
  const { position } = toRefs(props);

  // --

  const COL_SPACE = 100.0 / cols;
  const ROW_SPACE = 100.0 / rows;

  const col = ref(0);
  const row = ref(0);

  watch(
    position,
    (pos) => {
      const frame = (mod(pos, 1.0) * frames) | 0;

      col.value = (frame % cols) * COL_SPACE;
      row.value = ((frame / cols) | 0) * ROW_SPACE;
    },
    { immediate: true },
  );
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="container">
    <img
      :src="src"
      :style="{
        width: `${cols * 100.0}%`,
        height: `${rows * 100.0}%`,
        transform: `translate3d(-${col}%, -${row}%, 0.0)`,
      }"
      class="frames"
    />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .container {
    position: relative;
    overflow: hidden;

    .frames {
      position: absolute;

      width: 100%;
      height: 100%;
    }
  }
</style>
