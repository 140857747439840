<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <div class="logo" ref="logo">
    <Penrose small class="penrose" />
    <IconAnima class="anima" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .logo {
    display: flex;
    align-items: center;

    gap: px(16);

    margin-left: -px(2);
  }

  .anima {
    width: px(120);
  }
</style>
