<script setup>
  const CHARS =
    '!"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~';
  const NUM_CHARS = CHARS.length;

  // --

  const props = defineProps(['text', 'start', 'end', 'fade']);
  const scramble = ref(props.text);

  const { text } = toRefs(props);

  // --

  watch(
    props,
    ({ start, end, fade }) => {
      start = Math.round(start * text.value.length);
      end = Math.round(end * text.value.length) - 1;

      let output = '';

      for (let i = 0; i < start; i++) {
        output += Math.random() > fade ? '\xa0\xa0\xa0' : text.value.charAt(i);
      }

      for (let i = start; i < end; i++) {
        output +=
          Math.random() > fade
            ? '\xa0'
            : // ? '\xa0\xa0\xa0'
              CHARS.charAt(Math.floor(Math.random() * NUM_CHARS));
      }

      scramble.value = output;
    },
    { immediate: false },
  );
</script>

<!----------------------------------------------------------------------------->

<template>
  <span>{{ scramble }}</span>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  span {
    display: block;
    white-space: nowrap;
  }
</style>
