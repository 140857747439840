export default (callback) => {
  onMounted(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('orientationchange', onResize);

    window.addEventListener('scroll', onScroll);

    onResize();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize);
    window.removeEventListener('orientationchange', onResize);

    window.removeEventListener('scroll', onScroll);
  });

  // --

  let timer;
  let block;
  let request;

  function onResize() {
    request = false;

    if (block) {
      request = true;
    } else {
      callback(window.innerWidth, window.innerHeight, window.devicePixelRatio);
    }
  }

  function onScroll() {
    block = true;

    clearTimeout(timer);
    timer = setTimeout(() => {
      block = false;
      request && onResize();
    }, 2000.0);
  }
};
