<script setup>
  const { href, label } = defineProps(['href', 'label']);

  // --

  const text = ref();

  function onMouseEnter() {
    text.value.show(null, () => {}, 0.0);
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <a
    class="anchor"
    :target="useLinkTarget(href)"
    :href="href"
    @mouseenter="onMouseEnter"
    ><Scramble ref="text" :text="label" class="label"></Scramble><IconArrow
  /></a>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .anchor {
    display: inline-flex;
    align-items: center;

    gap: px(9);

    color: $orange;
    transition: color 0.15s $sineInOut;

    :deep(svg) {
      margin-top: px(1);
      path {
        fill: currentColor;
      }
    }

    @include hitbox;
    @include hover {
      transition-duration: 0.02s;
      color: $white;

      .label {
        @include blink(0.1s);
      }

      :deep(svg) {
        @include blink(0.09s);
      }
    }
  }
</style>
