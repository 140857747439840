<script setup>
  import IconCharacter01 from '~/components/icons/characters/IconCharacter01.vue';
  import IconCharacter02 from '~/components/icons/characters/IconCharacter02.vue';
  import IconCharacter03 from '~/components/icons/characters/IconCharacter03.vue';
  import IconCharacter04 from '~/components/icons/characters/IconCharacter04.vue';
  import IconCharacter05 from '~/components/icons/characters/IconCharacter05.vue';
  // import IconCharacter06 from '~/components/icons/characters/IconCharacter06.vue';
  import IconCharacter07 from '~/components/icons/characters/IconCharacter07.vue';
  import IconCharacter08 from '~/components/icons/characters/IconCharacter08.vue';
  import IconCharacter09 from '~/components/icons/characters/IconCharacter09.vue';
  // import IconCharacter10 from '~/components/icons/characters/IconCharacter10.vue';
  import IconCharacter11 from '~/components/icons/characters/IconCharacter11.vue';
  import IconCharacter12 from '~/components/icons/characters/IconCharacter12.vue';
  import IconCharacter13 from '~/components/icons/characters/IconCharacter13.vue';
  import IconCharacter14 from '~/components/icons/characters/IconCharacter14.vue';
  import IconCharacter15 from '~/components/icons/characters/IconCharacter15.vue';

  // --

  const characters = [
    IconCharacter01,
    IconCharacter02,
    IconCharacter03,
    IconCharacter04,
    IconCharacter05,
    // IconCharacter06,
    IconCharacter07,
    IconCharacter08,
    IconCharacter09,
    // IconCharacter10,
    IconCharacter11,
    IconCharacter12,
    IconCharacter13,
    IconCharacter14,
    IconCharacter15,
  ];

  const index = (Math.random() * characters.length) | 0;

  const flipX = Math.random() > 0.5;
  const flipY = Math.random() > 0.5;
</script>

<!----------------------------------------------------------------------------->

<template>
  <component :is="characters[index]" :class="{ flipX, flipY }" />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .flipX {
    transform: scaleX(-1);
  }

  .flipY {
    transform: scaleY(-1);
  }

  .flipX.flipY {
    transform: scale(-1, -1);
  }
</style>
