<template>
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8049 0.8125H25.9399L16.9061 11.1375L27.5336 25.1875H19.2124L12.6949 16.6662L5.23738 25.1875H1.09988L10.7624 14.1437L0.567383 0.8125H9.09988L14.9911 8.60125L21.8049 0.8125ZM20.3536 22.7125H22.6449L7.85488 3.1575H5.39613L20.3536 22.7125Z"
      fill="#A4A4A4"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(28);
    height: px(26);
  }
</style>
