import { PillowEffect, DoubleEffect } from '@pinkyellow/pillow';
import { Color } from 'three';

import gsap from 'gsap';

import AnimaVert from './shaders/anima.vert';
import AnimaFrag from './shaders/anima.frag';

// --

export default class AnimaEffect extends PillowEffect {
  // ---------------------------------------------------------------------------

  constructor() {
    super('AnimaEffect', AnimaVert, AnimaFrag, {
      _BACKGROUND: { value: new Color(0x0c0c0c) },
      _time: { value: 0.0 },
      _random: { value: 0.0 },
      _scrollSpeed: { value: 0.0 },
      _scrollDirection: { value: 0.0 },
      _grain: { value: 0.35 },
      _scan: { value: 0.03 },
      _scanlines: { value: 2048.0 },
      _flicker: { value: 0.0 },
      _visibility: { value: 0.0 },
      _ratio: { value: 1.0 },
    });

    this.__VISIBILITY = this._material.uniforms._visibility;
  }

  // ---------------------------------------------------------------------------

  _resize(width, height) {
    this._uniform('_ratio', height / width);
  }

  // ---------------------------------------------------------------------------

  _update({ et }, { random }) {
    this._uniform('_time', et * 0.03);
    this._uniform('_random', random);

    this._uniform('_flicker', random * 0.005);
  }
}
