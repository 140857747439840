<script setup>
  import gsap from 'gsap';

  // --

  const logo = ref();
  const logoLine = ref();
  const nav = ref();

  const darken = ref(false);

  // --

  let scrollPosition = 0.0;
  const THRESHOLD = 1000.0;

  let active = '';
  let request = '';
  let inTransition = false;
  let logoLineVisible = false;

  // --

  const header = ref();

  onVisibleScroll(header, (_scrollX, scrollY) => {
    const position = scrollY;

    if (position < THRESHOLD) {
      activate();
      darken.value = false;
    } else if (position < scrollPosition) {
      activate();
      darken.value = true;
    } else {
      deactivate();
      darken.value = false;
    }

    scrollPosition = position;
  });

  function top(force) {
    if (active === 'top' && !force) return;
    active = 'top';

    if (inTransition) {
      request = 'top';
      return;
    }

    inTransition = true;
    request = '';

    logo.value.show();
    nav.value.top();

    // --

    logoLine.value.style.transformOrigin = 'left top';

    gsap.killTweensOf(logoLine.value);
    gsap.fromTo(
      logoLine.value,
      { scaleX: logoLineVisible ? 1.0 : 0.0 },
      {
        scaleX: 1.0,
        force3D: true,
        delay: 0.94,
        duration: 0.78,
        ease: 'quint.inOut',
        onComplete: () => {
          inTransition = false;
          if (request === 'deactivate') deactivate(true);
          else if (request === 'activate') activate(true);
        },
      },
    );

    logoLineVisible = true;
  }

  function activate(force) {
    if (active === 'activate' && !force) return;
    active = 'activate';

    if (inTransition) {
      request = 'activate';
      return;
    }

    inTransition = true;
    request = '';

    logo.value.show();
    nav.value.activate();

    // --

    logoLine.value.style.transformOrigin = 'left top';

    gsap.killTweensOf(logoLine.value);
    gsap.fromTo(
      logoLine.value,
      { scaleX: logoLineVisible ? 1.0 : 0.0 },
      {
        scaleX: 1.0,
        force3D: true,
        delay: 0.94,
        duration: 0.78,
        ease: 'quint.inOut',
        onComplete: () => {
          inTransition = false;
          if (request === 'deactivate') deactivate(true);
          else if (request === 'top') top(true);
        },
      },
    );

    logoLineVisible = true;
  }

  function deactivate(force) {
    if (active === 'deactivate' && !force) return;
    active = 'deactivate';

    if (inTransition) {
      request = 'deactivate';
      return;
    }

    inTransition = true;
    request = '';

    logo.value.deactivate();
    nav.value.deactivate();

    // --

    logoLine.value.style.transformOrigin = 'right top';

    gsap.killTweensOf(logoLine.value);
    gsap.fromTo(
      logoLine.value,
      { scaleX: logoLineVisible ? 1.0 : 0.0 },
      {
        scaleX: 0.0,
        force3D: true,
        duration: 0.3,
        ease: 'quint.inOut',
        onComplete: () => {
          inTransition = false;
          if (request === 'activate') activate(true);
          else if (request === 'top') top(true);
        },
      },
    );

    logoLineVisible = false;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <header ref="header" :class="{ darken }">
    <div class="container">
      <div class="content">
        <NuxtLink to="/" class="left-side"> <Logo ref="logo" /><span ref="logoLine" /> </NuxtLink>
        <Nav ref="nav" />
      </div>
    </div>
  </header>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  header {
    position: fixed;
    z-index: $layer-header;

    pointer-events: none;

    top: 0;

    width: 100%;
    height: $header-height;

    @include break-medium {
      height: $header-height-medium;
    }

    @include break-small {
      display: none;
    }

    @mixin hide-transition {
      transition: transform 0.5s $cubicInOut 0.28s;
    }

    @mixin show-transition {
      transition: transform 0.8s $cubicInOut;
    }

    &:before {
      content: '';
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: px(160);

      background-image: linear-gradient(0, transparent 0%, $black 90%);
      opacity: 0;

      transition: opacity 0.51s $sineInOut 0.66s;
    }

    &.darken {
      &:before {
        opacity: 1;
        transition-delay: 0.1s;
      }
    }

    .container {
      position: relative;
      height: 100%;
    }

    .content {
      position: absolute;

      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      height: calc(100%);

      // top: -100%;
      bottom: 0;

      padding: 0 px(22);

      .left-side {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        pointer-events: none;

        gap: px(20);

        // margin-top: px(36);
        margin-top: px(38);

        span {
          width: 100%;
          height: px(1);

          background-color: $white-20;
        }
      }
      @include break-medium {
        padding: 0 px(20);

        .left-side {
          margin-top: px(43);
          margin-left: 0;
        }
      }
    }
  }
</style>
