<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <div>
    <slot />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    width: 100%;
    max-width: px(2160);

    padding-left: $margin;
    padding-right: $margin;

    @include break-max {
      padding-left: $margin-max;
      padding-right: $margin-max;
    }

    @include break-large {
      padding-left: $margin-large;
      padding-right: $margin-large;
    }

    @include break-medium {
      padding-left: $margin-medium;
      padding-right: $margin-medium;
    }

    @include break-small {
      padding-left: $margin-small;
      padding-right: $margin-small;
    }

    @include break-phone {
      padding-left: $margin-phone;
      padding-right: $margin-phone;
    }
  }
</style>
