import { defineStore } from 'pinia';
import { layout } from '~/pinkyellow/satanic-api';

export const useBaseStore = defineStore('base', {
  state: () => ({
    base: {},
  }),

  getters: {
    getNavBar(state) {
      return state.base.nav.main_menu;
    },

    getFooter(state) {
      return state.base.nav.main_footer;
    },

    getPresets(state) {
      return state.base.image.sizes;
    },

    getCdnCropUrl(state) {
      return state.base.image.cdn_crop_url;
    },

    getCdnBaseUrl(state) {
      return state.base.image.cdn_base_url;
    }
  },

  actions: {
    async fetchLayout() {
      this.base = await layout({
        max_depth: 1,
        fields: ['title', 'url', 'type'],
      });
    },
  },
});
