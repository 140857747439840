<script setup>
  const { small } = defineProps({ small: Boolean });

  // --

  const container = ref();

  const scrollDirection = inject('scrollDirection');
  const scrollSpeed = inject('scrollSpeed');

  const ITERATION_DISTANCE = 1000.0;
  const IDLE = 12.0;
  const SPEED = 0.35;

  const position = ref(0.0);

  onRender(container, () => {
    position.value =
      position.value - scrollDirection.value * IDLE - scrollSpeed.value * SPEED;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="container" :class="{ small }">
    <SpriteAnimation
      class="sprite"
      :src="useCdn().generateStaticAsset('penrose.png')"
      cols="30"
      rows="1"
      frames="30"
      :position="position / ITERATION_DISTANCE"
    />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .sprite {
    width: px(56);
    height: px(56);

    margin: -px(3) 0;
  }

  .small {
    .sprite {
      width: px(32);
      height: px(32);
    }
  }
</style>
