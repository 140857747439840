import Pillow, { PillowObject } from '@pinkyellow/pillow';
import { Mesh, SphereGeometry, MeshBasicMaterial, PointLight } from 'three';

// --

export default class AnimaOrb extends PillowObject {
  // ---------------------------------------------------------------------------

  _create({ isTouch }) {
    this._touch = Pillow().touch;

    this._orb = this._add(this._container());
    this._body = this._add(
      new Mesh(
        new SphereGeometry(0.04),
        new MeshBasicMaterial({
          transparent: true,
          color: 0xffffff,

          depthWrite: false,
        }),
      ),
      this._orb,
    );

    this._intensity = 10.0;
    this._light = this._add(new PointLight(0xffffff, this._intensity, 1.2, 1.0), this._orb);
  }

  // ---------------------------------------------------------------------------

  _resize(width, height) {
    this._ratio = height / width;
  }

  // ---------------------------------------------------------------------------

  zoomIn() {
    this._intensity = 2.5;
    this._light.intensity = this._intensity;

    this._orb.scale.set(0.4, 0.4, 0.4);
  }

  zoomOut() {
    this._intensity = 10.0;
    this._light.intensity = this._intensity;

    this._orb.scale.set(1.0, 1.0, 1.0);
  }

  // ---------------------------------------------------------------------------

  _update({ et }, { x, y }) {
    this._orb.position.x = x;
    this._orb.position.y = y + Math.sin(et) * 0.02;
  }

  charge(charge) {
    this._light.intensity = this._intensity + charge * 10.0;
  }

  // ---------------------------------------------------------------------------

  get body() {
    return this._body;
  }
}
