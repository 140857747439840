import { MeshLambertMaterial, Fog } from 'three';
import { EffectPass, GodRaysEffect } from 'postprocessing';
import gsap from 'gsap';

import AnimaScene from './anima-scene';
import AnimaOrb from '../objects/anima-orb';

// --

export default class AnimaScene01 extends AnimaScene {
  // ---------------------------------------------------------------------------

  _setupSceneEffects(properties) {
    this._orb = this._add(new AnimaOrb(properties));

    return [
      new EffectPass(
        this._camera,
        (this._godRays = new GodRaysEffect(this._camera, this._orb.body, {
          samples: 60.0,
          blur: true,
          exposure: 4.0,
          // clampMax: 2.0,
          density: 1.0,
          decay: 0.9,
        })),
      ),
    ];
  }

  _setupPrism() {
    return { material: new MeshLambertMaterial({ color: 0x666666 }) };
  }

  _setupScene() {
    this.fog = new Fog(0x0c0c0c, 2.5, 5.5);
  }

  // ---------------------------------------------------------------------------

  // _resizeScene(width, height) {
  // }

  // ---------------------------------------------------------------------------

  _leave(properties) {
    super._leave(properties);
    clearTimeout(this._timer);
  }

  // ---------------------------------------------------------------------------

  _zoomIn() {
    this._orb.zoomIn();
    this.fog.near = 0.0;
    this.fog.far = 3.0;

    this._godRays.godRaysMaterial.density = 0.92;
  }

  _zoomOut() {
    this._orb.zoomOut();

    this.fog.near = 2.5;
    this.fog.far = 5.5;

    this._godRays.godRaysMaterial.density = 1.0;
  }

  // ---------------------------------------------------------------------------

  _updateScene(time, { x, y, random }) {
    this._orb.update(time, { x, y });
  }

  charge(charge) {
    this._orb.charge(charge);
    this._godRays.godRaysMaterial.density = 1.0 + charge * 0.2;
  }
}
