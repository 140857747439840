<script setup>
  let scrollPosition = 0.0;
  const THRESHOLD = 500.0;

  const header = ref();

  const active = ref(false);

  onVisibleScroll(header, (_scrollX, scrollY) => {
    const position = scrollY;

    if (position < THRESHOLD) {
      active.value = true;
    } else if (position <= scrollPosition) {
      active.value = true;
    } else {
      active.value = false;
    }

    scrollPosition = position;
  });

  // --

  const navActive = ref();
  provide('navActive', navActive);

  function toggleNav() {
    navActive.value = !navActive.value;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <header ref="header" :class="{ active }">
    <div class="inner"><LogoMobile /><Hamburger @click="toggleNav" /></div>
  </header>
  <NavMobile class="nav-mobile" :class="{ navActive }" />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .nav-mobile {
    display: none;

    &.navActive {
      display: block;
    }
  }

  header {
    position: fixed;
    z-index: $layer-header;

    pointer-events: none;

    @include break-min-small {
      display: none;
    }

    top: 0;

    width: 100%;
    height: $header-height-small;

    // background-color: $black;
    // border-bottom: px(1) solid $white-20;

    // --

    // &.darken {
    //   &:before {
    //     opacity: 1;
    //   }
    // }

    // --

    .inner {
      width: 100%;
      height: 100%;

      padding: 0 $margin-small;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &:before {
        content: '';
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: px(160);

        background-image: linear-gradient(0, transparent 0%, $black 90%);
        z-index: $layer-back;
      }

      transition: transform 0.5s $cubicInOut 0.2s;
      transform: translate3d(0, -100%, 0);
    }

    &.active {
      .inner {
        transform: translate3d(0, 0, 0);
        transition: transform 0.52s $quadInOut 0.018s;
      }
    }

    @include break-phone {
      height: $header-height-phone;

      .inner {
        padding: 0 $margin-phone;
      }
    }
  }
</style>
