<script setup>
  import gsap from 'gsap';

  // --

  const { label, primary, delay, disabled } = defineProps({
    primary: Boolean,
    label: String,
    delay: Number,
    disabled: Boolean,
  });

  // --

  let isVisible = false;
  const visible = ref(false);

  const border = ref();
  const text = ref();

  const active = ref(false);
  const hover = ref(false);

  const blink = ref(false);

  function activate() {
    if (isVisible) return;
    isVisible = true;

    if (!primary) border.value.show(delay);
    else {
      // if (visible.value) border.value.hide(delay);
      if (!visible.value) border.value.show(delay);
    }

    active.value = true;

    if (!visible.value) {
      blink.value = false;

      gsap.killTweensOf(blinkLabel);
      gsap.delayedCall(delay, blinkLabel);
    }
  }

  function deactivate() {
    if (!isVisible) return;
    isVisible = false;

    if (!primary) border.value.hide(delay);
    else border.value.show(delay);

    active.value = false;
  }

  function blinkLabel() {
    visible.value = true;
    blink.value = true;
    // text.value.show(null, () => {}, 0.0);
  }

  // --

  function onMouseEnter() {
    text.value.show(null, () => {}, 0.0);
    hover.value = true;
  }

  function onMouseLeave() {
    if ((active.value && !primary) || (!active.value && primary)) border.value.show();
    hover.value = false;
  }

  // --

  defineExpose({ activate, deactivate });
</script>

<!----------------------------------------------------------------------------->

<template>
  <button
    :class="{ primary, secondary: !primary, active, visible, disabled }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <Border ref="border" :orange="primary && active"
      ><span class="inner"><Scramble ref="text" :text="label" class="label" :class="{ blink }" /></span
    ></Border>
  </button>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  button {
    @include hitbox(0.25rem, 1rem);

    // transition: color 0.16s $sineInOut;
    pointer-events: auto;

    @include hover {
      .inner {
        background-color: $white;
        color: $black;

        transition-duration: 0.03s;
        transition-delay: 0s;
      }
    }

    .inner {
      position: relative;
      z-index: 1;

      display: block;
      padding: px(4) px(15) px(4.5);

      visibility: hidden;

      transition: background-color 0.08s $sineInOut;

      @include break-medium {
        padding: px(3) px(12) px(3);
      }
    }

    &.visible {
      .inner {
        visibility: visible;
      }
    }

    &.primary {
      color: $white;

      background-color: transparent;
      transition:
        color 0.28s $sineInOut 0.12s,
        background-color 0.28s $sineInOut 0.12s;

      &.visible {
        background-color: transparent;
        color: $white;

        &.active {
          background-color: $orange;
          color: $black;

          transition-delay: 0.18s;
        }
      }
    }

    &.secondary {
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
</style>
