import Pillow, { PillowEffect } from '@pinkyellow/pillow';
import { Color, Vector2, Vector3 } from 'three';

import gsap from 'gsap';

import AnimaVert from '../shaders/anima.vert';
import AnimaFractalFrag from '../shaders/anima-fractal.frag';

// --

export default class AnimaFractal extends PillowEffect {
  // ---------------------------------------------------------------------------

  constructor() {
    super('AnimaEffect', AnimaVert, AnimaFractalFrag, {
      _BACKGROUND: { value: new Color(0x0c0c0c) },
      _ORANGE: { value: new Color(0xfe5c00) },
      _COLOR: { value: new Color(0xffffff) },
      _time: { value: 0.0 },
      _rotation: { value: 0.0 },
      _visibility: { value: 0.0 },
      _location: { value: new Vector2(0.0, 0.0) },
      _camera: { value: new Vector3(0.0, 0.0, 0.0) },
      _ratio: { value: 1.0 },
      _zoomMultiplier: { value: 0.0 },
    });

    this.__VISIBILITY = this._material.uniforms._visibility;
    this.__LOCATION = this._material.uniforms._location;
  }

  // ---------------------------------------------------------------------------

  _resize(width, height) {
    this._ratio = height / width;
    this._uniform('_ratio', this._ratio);
  }

  // ---------------------------------------------------------------------------

  setCamera(x, y, z) {
    this._uniform('_camera', new Vector3(x, y, z));
  }

  // --

  zoomIn() {
    this._uniform('_zoomMultiplier', 1.0);
  }

  zoomOut() {
    this._uniform('_zoomMultiplier', 0.0);
  }

  // ---------------------------------------------------------------------------

  _update({ et }, { rotation, x, y }) {
    this.__LOCATION.value.x = x;
    this.__LOCATION.value.y = y;

    this._uniform('_time', et);
    this._uniform('_rotation', -rotation);
  }
}
