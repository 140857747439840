<script setup>
  import Telescope from '~/pinkyellow/telescope';
  import gsap from 'gsap';

  // --

  const container = ref();
  const props = defineProps({
    delay: {
      type: Number,
      default: 0.0,
    },
    text: {
      type: String,
      default: ' ',
    },
  });

  const { text = '', delay } = toRefs(props);

  let words = '';
  const lines = ref([]);

  // --

  const start = ref(0.0);
  const end = ref(0.0);
  const fade = ref(0.0);

  function show(el, done, wait = delay.value) {
    gsap.killTweensOf(start);
    gsap.killTweensOf(end);
    gsap.killTweensOf(fade);

    fade.value = 1.0;

    gsap.fromTo(end, { value: 0.0 }, { value: 1.0, duration: 0.12, delay: wait, ease: 'quad.inOut' });
    gsap.fromTo(
      start,
      { value: 0.0 },
      {
        value: 1.0,
        duration: 0.39,
        delay: wait,
        ease: 'cubic.inOut',
        onComplete: done,
      },
    );
  }

  // --

  let resizeObserver;
  const visible = ref(false);

  onMounted(() => {
    words = text.value.split(' ');
    watch(text, () => {
      words = text.value.split(' ');
      update();
    });

    // --

    resizeObserver = new ResizeObserver(update);
    resizeObserver.observe(container.value);

    Telescope.detect(container.value, {
      visible: () => {
        visible.value = true;
      },
      hidden: () => {
        visible.value = false;
      },
    });
  });

  onBeforeUnmount(() => {
    resizeObserver.unobserve(container.value);
    Telescope.ignore(container.value);
  });

  // --

  function update() {
    const el = container.value.appendChild(document.createElement('div'));

    let height = 0.0;

    lines.value = [''];
    let line = 0;

    words.forEach((word) => {
      el.textContent += word;

      if (el.offsetHeight > height) {
        lines.value.push('');
        line++;
      } else {
        el.textContent += ' ';
      }

      height = el.offsetHeight;
      lines.value[line] += `${word} `;
    });

    lines.value.shift();
    container.value.removeChild(el);

    // console.log(lines.value);
  }

  // --

  function hide(wait = delay.value, callback = () => {}) {
    gsap.killTweensOf(start);
    gsap.killTweensOf(fade);

    gsap.to(start, {
      value: 0.0,
      duration: 0.05,
      delay: wait,
      ease: 'quad.out',
    });

    gsap.fromTo(
      fade,
      { value: 1.0 },
      {
        value: 0.0,
        duration: 0.18,
        delay: wait,
        ease: 'quad.in',
        onComplete: callback,
      },
    );
  }

  defineExpose({ show, hide });
</script>

<!----------------------------------------------------------------------------->

<template>
  <span class="container" ref="container">
    <Transition @enter="show" :css="false">
      <span class="scramble" v-show="visible">
        <ScrambleLine
          v-for="(line, index) in lines"
          :key="index"
          :text="line"
          :start="start"
          :end="end"
          :fade="Math.pow(fade, index + 1)"
        ></ScrambleLine>
      </span>
    </Transition>
    <span class="text">{{ text }}</span>
  </span>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .container {
    position: relative;
    display: block;
  }

  .scramble {
    position: absolute;

    top: 0;
    left: 0;
  }

  .text {
    visibility: hidden;
  }
</style>
