export default (ref, callback) => {
  let visible = false;

  useTelescope(ref, {
    visible: () => show(),
    partial: () => show(),
    hidden: () => hide(),
  });

  // --

  let scroll = () => {};

  function show() {
    if (visible) return;
    visible = true;

    scroll = callback;
  }

  function hide() {
    if (!visible) return;
    visible = false;

    scroll = () => {};
  }

  onScroll((scrollX, scrollY) => {
    scroll(scrollX, scrollY);
  });
};
