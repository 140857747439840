import { PillowConfig, PillowCamera } from '@pinkyellow/pillow';

import AnimaEffect from './anima-effect';

import AnimaScene01 from './scenes/anima-scene-01';
import AnimaScene02 from './scenes/anima-scene-02';

import { ColorManagement } from 'three';

// --

export default class AnimaConfig extends PillowConfig {
  // ---------------------------------------------------------------------------

  _setupPillow(WORLD, properties) {
    ColorManagement.enabled = false;

    return {
      ...super._setupPillow(WORLD, properties),

      powerPreference: 'high-performance',
      antialias: false,

      // minAspectRatio: 1.0,
      maxAspectRatio: 2.0,
    };
  }

  _setupRenderer(WORLD, renderer, properties) {
    /* virtual */
  }

  _setupModules(WORLD, properties) {
    return [...super._setupModules(WORLD, properties)];
  }

  _setupStory(WORLD, properties) {
    const camera = new PillowCamera(0.1, 10.0, { fov: 20.0 });
    camera.position.z = 5.0;

    const config = { ...properties, camera, effect: new AnimaEffect() };

    return [new AnimaScene01(config), new AnimaScene02(config)];
  }
}
