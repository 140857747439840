import Pillow, { PillowScene, state } from '@pinkyellow/pillow';
import { Fog, Color, Vector3 } from 'three';

import AnimaPrism from '../objects/anima-prism';

// --

export default class AnimaScene extends PillowScene {
  // ---------------------------------------------------------------------------

  _setupEffects({ effect }) {
    return [...this._setupSceneEffects(), (this._effect = effect)];
  }

  _setupSceneEffects() {
    /* virtual */
    return [];
  }

  _create(properties) {
    const { isTouch } = properties;

    this._isTouch = isTouch;
    this._touch = Pillow().touch;

    // this._scrollPosition = 0.0;
    // this._scrollProgress = 0.0;
    // this._scrollDirection = 1.0;
    // this._scrollSpeed = 0.0;

    this.background = new Color(0x0c0c0c);

    this._prism = this._add(new AnimaPrism({ isTouch, ...this._setupPrism() }));
    this._prism.position.set(0.0, 0.08, 0.0);

    this._x = 0.0;
    this._y = 0.0;

    state(this, '_updatePosition', {
      camera: this.__updatePosition,
      raw: this.__updatePositionRaw,
    });

    this._setupScene(properties);
  }

  _setupPrism() {
    /* virtual */
    return {};
  }

  _setupScene() {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  _resize(width, height) {
    this._updateFrame();
    this._resizeScene(width, height);
  }

  _updateFrame() {
    const frame = this._camera.getFrame(new Vector3(this._camera.position.x, this._camera.position.y, 0.0));

    this._frameWidth = frame.width * 0.5;
    this._frameHeight = frame.height * 0.5;
  }

  _resizeScene(width, height) {
    /* virtual */
  }

  // scroll(position, progress, direction, speed) {
  //   this._scrollPosition = position;
  //   this._scrollProgress = progress;
  //   this._scrollDirection = direction;
  //   this._scrollSpeed = speed;
  // }

  // ---------------------------------------------------------------------------

  zoomIn() {
    this._camera.position.set(-0.35, -0.15, 0.8);
    this._prism.position.z = -1.2;
    this._updateFrame();

    this._zoomIn();
  }

  zoomOut() {
    this._camera.position.set(0.0, 0.0, 5.0);
    this._prism.position.z = 0.0;
    this._updateFrame();

    this._zoomOut();
  }

  _zoomIn() {
    /* virtual */
  }

  _zoomOut() {
    /* virtual */
  }

  // ---------------------------------------------------------------------------

  _update(time, { x, y, random }) {
    this._prism.update(time);

    this._effect.update(time, {
      random,
      // scrollSpeed: this._scrollSpeed,
      // scrollDirection: this._scrollDirection,
    });

    this._updatePosition(x, y);

    this._updateScene(time, {
      x: this._x,
      y: this._y,
      random,
    });
  }

  __updatePosition(x, y) {
    this._x = this._camera.position.x + x * this._frameWidth;
    this._y = this._camera.position.y + y * this._frameHeight;
  }

  __updatePositionRaw(x, y) {
    this._x = x;
    this._y = y;
  }

  charge(charge) {
    /* virtual */
  }
}
