import { tick, tock } from '~/pinkyellow/ticktock';

// --

export default (callback) => {
  onMounted(() => {
    tick(callback);
  });

  onBeforeUnmount(stopTick);

  function stopTick() {
    tock(callback);
  }

  return stopTick;
};
