<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.1541 0L12 10.1541L1.84589 0L0 1.84589L10.1541 12L0 22.1541L1.84589 24L12 13.8459L22.1541 24L24 22.1541L13.8459 12L24 1.84589L22.1541 0Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(24);
    height: px(24);
  }
</style>
