<script setup>
  import gsap from 'gsap';

  // --

  const { loaderProgress, loaderComplete } = useLoader();

  const progressTimer = ref(0.0);
  const virtualProgress = ref(0.0);

  // const percentage = computed(() => (virtualProgress.value * 100.0) | 0);
  // watch(percentage, (value) => {});

  // --

  const logo = ref();
  const bar = ref();
  let progressBar;

  onMounted(() => {
    logo.value.show();

    gsap.killTweensOf(bar.value);
    gsap.to(bar.value, {
      scaleX: 1.0,
      force3D: true,
      delay: 0.7,
      duration: 1.2,
      ease: 'quint.inOut',
    });

    progressBar = bar.value.querySelector('span');

    // --

    gsap.killTweensOf(progressTimer);
    gsap.to(progressTimer, {
      value: 1.0,
      delay: 1.4,
      // duration: 0.4,
      duration: 0.4,
      ease: 'none',
    });
  });

  const stopTick = onTick(() => {
    const targetProgress = Math.min(progressTimer.value, loaderProgress.value);
    virtualProgress.value += (targetProgress - virtualProgress.value) * 0.55;

    progressBar.style.transform = `scale3d(${virtualProgress.value}, 1.0, 1.0)`;

    if (virtualProgress.value > 0.999) {
      finish();
      stopTick();
    }
  });

  // --

  watch(loaderComplete, () => {
    loaderComplete.value && finish();
  });

  const emit = defineEmits(['finished']);
  let finished = 0;

  function finish() {
    finished === 1 && hide();
    finished++;
  }

  function hide() {
    logo.value.hide();

    bar.value.style.transformOrigin = 'right top';

    gsap.killTweensOf(bar.value);
    gsap.to(bar.value, {
      scaleX: 0.0,
      force3D: true,
      duration: 0.7,
      ease: 'cubic.inOut',
      onComplete: () => {
        emit('finished');
      },
    });
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="loader">
    <div class="container">
      <Logo ref="logo" />
      <div ref="bar" class="bar"><span /></div>
    </div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .loader {
    position: fixed;
    z-index: $layer-loader;

    user-select: none;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: progress;

    background-color: $background-black;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: px(58);
    }

    .bar {
      width: px(592);
      height: px(1);

      max-width: 80vw;

      transform-origin: left top;
      transform: scale3d(0, 1, 1);

      background-color: $dark-grey;

      span {
        position: absolute;

        width: 100%;
        height: 100%;

        background-color: $orange;

        transform-origin: left top;
        transform: scale3d(0, 1, 1);
      }
    }
  }
</style>
