<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <Wrapper>
    <div class="grid">
      <slot />
    </div>
  </Wrapper>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .grid {
    @include grid;
  }
</style>
