<script setup>
  const footer = ref();
  const offset = ref(0.0);

  const ratio = ref(0.0);

  onRender(footer, () => {
    const rect = footer.value.getBoundingClientRect();
    const visible = rect.top - window.innerHeight;

    offset.value = -visible;
    ratio.value = (-visible / rect.height) * 1.75 - 0.75;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <footer ref="footer" :style="{ '--ratio': ratio }">
    <div
      class="tab"
      :style="{ transform: `translate3d(${-ratio * 100}%, 0.0, 0.0)` }"
    >
      <span />
    </div>
    <div class="container">
      <div
        class="content"
        :style="{
          transform: `translate3d(${(-1 + ratio) * 0}%, ${offset}px, 0.0)`,
          opacity: ratio,
        }"
      >
        <Grid mediumWide>
          <div class="inner">
            <FooterSignup />
            <div class="bottom">
              <IconAnima class="anima" /><span class="right"
                ><a href="https://twitter.com/animalabs_io" target="_blank"
                  ><IconX /></a
                ><span>©2024</span></span
              >
            </div>
          </div></Grid
        >
      </div>
    </div>
  </footer>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  footer {
    position: relative;

    @include roboto-mono-medium(px(16));

    background-color: rgba(0, 0, 0, 0.1);
    border-top: px(1) solid $white-20;

    height: $footer-height;

    @include break-medium {
      font-size: px(14);
    }

    @include break-small {
      height: $footer-height-small;
    }

    user-select: none;

    .tab {
      $width: 146;
      $height: 70;

      position: absolute;

      display: none;

      top: -px($height);
      right: -px($width);

      width: px($width);
      height: px($height);

      background-color: rgba(0, 0, 0, 0.2);
      border-top: px(1) solid $white-20;

      span {
        position: absolute;

        $side: px(98.9949493661 /* sqrt(70 * 70 + 70 * 70) */ - 1.5);

        width: $side;
        height: $side;

        background-color: rgba(0, 0, 0, 0.2);
        border-top: px(1) solid $white-20;

        top: px(19.2);
        left: -px(48.5);

        transform: rotate(-45deg);
      }
    }

    .container {
      position: relative;
      overflow: hidden;

      height: 100%;
    }

    .content {
      position: absolute;

      display: flex;
      justify-content: center;

      width: 100%;
      height: 100%;

      top: -100%;
      bottom: 0;

      padding: px(87) 0 px(48);

      > div {
        height: 100%;
        &:deep(.grid) {
          height: 100%;
        }
      }
    }

    .inner {
      height: 100%;

      display: flex;
      flex-direction: column;

      justify-content: space-between;

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        font-size: px(14);
        color: $white-40;

        // padding: 0 px(24);

        .right {
          display: flex;
          align-items: flex-end;

          gap: px(52);

          opacity: var(--ratio);

          > * {
            &:first-child {
              opacity: var(--ratio);
            }
          }
        }

        a {
          @include hitbox;
          margin-bottom: -px(3);

          :deep(path) {
            fill: currentColor;
            transition: fill 0.17s $sineInOut;
          }

          @include hover {
            :deep(path) {
              fill: $white;
            }
          }
        }
      }
    }

    .anima {
      overflow: visible;

      :deep(path) {
        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            opacity: calc((6 - $i) / 5 - (1 - var(--ratio)));
            // transform: translate3d(
            //   calc(($i - 1) * (1 - var(--ratio)) * 5%),
            //   0,
            //   0
            // );
          }
        }

        // @for $i from 1 through 5 {
        //   &:nth-child(#{$i}) {
        //     opacity: calc(($i) / 5 - (1 - var(--ratio)));
        //     transform: translate3d(
        //       calc((5 - $i) * (1 - var(--ratio)) * -15%),
        //       0,
        //       0
        //     );
        //   }
        // }
      }
    }
  }
</style>
