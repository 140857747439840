<script setup>
  const { signupOverlayActive } = useSignupOverlay();

  // --

  import { useForm } from 'laravel-precognition-vue';
  import { useReCaptcha } from 'vue-recaptcha-v3';

  const recaptchaInstance = useReCaptcha();

  const recaptcha = async () => {
    await recaptchaInstance?.recaptchaLoaded();
    const token = await recaptchaInstance?.executeRecaptcha('subscribe');
    return token;
  };

  const subscribeUrl = useRuntimeConfig().public.statamicApiUrl + '/subscribe';

  const form = useForm('post', subscribeUrl, {
    first_name: '',
    last_name: '',
    email: '',
    _recaptcha: '',
  });

  recaptcha().then((token) => {
    form._recaptcha = token;
  });

  const consent = ref(false);
  const success = ref(false);
  const validation = ref('');

  const submit = () =>
    form
      .submit()
      .then((response) => {
        validation.value = '';
        success.value = true;
        form.reset();
      })
      .catch((error) => {
        validation.value = error.response.data.message;
      });

  // --

  function onCloseClick() {
    if (signupOverlayActive.value) {
      signupOverlayActive.value = false;
      form.reset();
      consent.value = false;
      success.value = false;
      validation.value = '';
    }
  }

  watch(signupOverlayActive, (value) => {
    if (value) {
      form.email = useSignupOverlay().signupEmail.value;
      useSignupOverlay().signupEmail.value = '';
    }
  });

  // --

  const subtitle = 'Join the movement';
  const title = 'Get ready to enter the Nexus';
  const body = `<p>The first step in joining the ecosystem. Sign up and receive updates for Anima Labs’ upcoming title, ACT, Anima’s gaming storefront, Nexus, and our upcoming Genesis Drop.</p>`;
  const image = useCdn().generateStaticAsset('images/signup.png');
  const button = 'Submit';

  const subtitle2 = 'Join the movement';
  const title2 = 'Thank you';
  const body2 = 'You have successfully signed up for the newsletter.';
  const button2 = 'Close';
</script>

<!----------------------------------------------------------------------------->

<template>
  <div v-if="signupOverlayActive" class="overlay">
    <div class="panel">
      <div class="top">
        <div class="characters"><CharacterSequence :maxLength="5" /><CharacterSequence :maxLength="3" /></div>
        <div class="close">
          <CloseButton @click="onCloseClick" />
        </div>
      </div>

      <div class="content">
        <form v-if="!success" @submit.prevent="submit" @keydown.enter="submit">
          <Text :title="title" :subtitle="subtitle" :body="body" />

          <div class="inputs">
            <input
              :class="{ error: form.invalid('first_name') }"
              placeholder="First name"
              v-model="form.first_name"
              @change="form.validate('first_name')"
            />
            <input
              :class="{ error: form.invalid('last_name') }"
              placeholder="Last name"
              v-model="form.last_name"
              @change="form.validate('last_name')"
            />

            <input
              :class="{ error: form.invalid('email') }"
              placeholder="Email"
              type="email"
              v-model="form.email"
              @change="form.validate('email')"
            />

            <input type="hidden" v-model="form._recaptcha" />
          </div>

          <!-- <div class="terms">
            <input type="checkbox" v-model="consent" />
            <p>I have read and agree with the ANIMA <a href="/terms-of-use">Terms & Conditions</a>.</p>
          </div> -->

          <div class="policy">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
            <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
          </div>

          <Button
            @click="submit"
            class="button"
            :class="{ disabled: form.processing || !consent }"
            :label="button"
            to="javascript:;"
          ></Button>

          <span v-if="validation" class="validation-message">{{ validation }}</span>
        </form>

        <form v-else>
          <Text :title="title2" :subtitle="subtitle2" :body="body2" />
          <Button
            outline
            color="white"
            @click="onCloseClick"
            class="button continue"
            :label="button2"
            to="javascript:;"
          ></Button>
        </form>
        <figure><img :src="image" /></figure>
      </div>
    </div>

    <div class="background" @click="onCloseClick" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .overlay {
    position: fixed;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: $layer-overlay;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .panel {
    position: relative;

    background-color: $black;
    border: px(1) solid $dark-grey;

    border-radius: px(28);
    width: 80vw;

    overflow: hidden;

    .top {
      padding: px(28) px(35) px(17);
    }

    .characters {
      display: flex;
      gap: px(16);
    }

    .close {
      position: absolute;

      right: px(34);
      top: px(31);
    }

    .policy {
      max-width: px(520);

      a {
        text-transform: none;
        text-decoration: underline;

        @include inter(px(16));

        @include hover {
          color: $orange;
        }
      }
    }

    .content {
      display: flex;
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      gap: px(48);

      width: 44%;
      background-color: $black-1;

      @include break-large {
        width: 100%;
      }

      padding: px(85) px(35) px(72);

      &:deep(.text) {
        max-width: px(520) !important;
      }

      .inputs {
        display: flex;
        flex-direction: column;
        gap: px(16);

        max-width: px(520);
      }

      input {
        padding: 0 0 px(14);
        border-bottom: px(1) solid $white-40;

        // @include roboto-mono-medium(px(12));
        text-transform: none;

        &:focus {
          border-color: $white;
        }

        // height: px(7);

        &::placeholder {
          color: $white-40;
        }
      }

      .button {
        align-self: flex-start;
        margin-top: -px(20);
      }

      .validation-message {
        position: absolute;
        color: $orange;

        bottom: px(22);
        padding-right: px(20);
      }
    }

    figure {
      width: 56%;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      @include break-large {
        display: none;
      }
    }
  }

  .background {
    position: absolute;

    top: 0;
    left: 0;

    background-color: rgba(21, 21, 21, 0.85);

    width: 100%;
    height: 100%;

    z-index: -1;
  }
</style>
