import { tick, tock } from '~/pinkyellow/ticktock';

// --

export default (ref, callback) => {
  let visible = false;

  useTelescope(ref, {
    visible: () => show(),
    partial: () => show(),
    hidden: () => hide(),
  });

  // --

  function show() {
    if (visible) return;
    visible = true;

    tick(callback);
  }

  function hide() {
    if (!visible) return;
    visible = false;

    tock(callback);
  }

  // --

  onBeforeUnmount(() => {
    tock(callback);
  });
};
