import { default as _91_46_46_46page_93bbvW8s9pnrMeta } from "/vercel/path0/nuxt/pages/[...page].vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93bbvW8s9pnrMeta?.name ?? "page",
    path: _91_46_46_46page_93bbvW8s9pnrMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93bbvW8s9pnrMeta || {},
    alias: _91_46_46_46page_93bbvW8s9pnrMeta?.alias || [],
    redirect: _91_46_46_46page_93bbvW8s9pnrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/nuxt/pages/[...page].vue").then(m => m.default || m)
  }
]