import Pillow, { PillowWorld, state } from '@pinkyellow/pillow';
import AnimaConfig from './anima-config';

import gsap from 'gsap';

// --

export default class Anima extends PillowWorld {
  // ---------------------------------------------------------------------------

  __config() {
    return AnimaConfig;
  }

  _create({ isTouch, onLoadProgress, onLoadComplete, onCursorDown, onCursorUp }) {
    this._touch = Pillow().touch;
    this._isTouch = isTouch;
    // --
    this._onLoadProgress = onLoadProgress;
    this._onLoadComplete = onLoadComplete;

    this._cursorDownCallback = onCursorDown;
    this._cursorUpCallback = onCursorUp;

    // --
    [
      // 'images/avatar_01.png',
      // 'images/avatar_02.png',
      // 'images/avatar_03.png',
      // 'images/chainsaw.png',
      // 'images/deagle.png',
      // 'images/sword.png',
    ].forEach((image) => Pillow().load.texture(image));

    // --

    this._x = 0.0;
    this._y = 0.18;

    this._targetX = 0.0;
    this._targetY = 0.18;

    this._magnet = 1.0;
    this._ease = 1.0;

    this._charge = 0.0;
    this._index = -1;

    state(this, '_move', {
      magnet: this.__magnet,
      move: isTouch ? () => {} : this.__move,
    });

    state(this, '_updateCharge', {
      off: () => {},
      on: this.__updateCharge,
    });

    // --

    this._touch.on('move', (e) => this._onCursorMove(e));
    this._touch.on('down', (e) => this._onCursorDown(e));
    this._touch.on('up', (e) => this._onCursorUp(e));
  }

  // ---------------------------------------------------------------------------

  _loadProgress(properties) {
    this._onLoadProgress(Pillow().load.progress);
  }

  _start() {
    this._onLoadComplete();
    this._next();
  }

  // _zoomIn() {
  //   this._zoomedIn = true;

  //   this._story.action((scene) => {
  //     scene.zoomIn();
  //   });
  // }

  // _zoomOut() {
  //   this._zoomedIn = false;

  //   this._story.action((scene) => {
  //     scene.zoomOut();
  //   });
  // }

  _next() {
    this._index = this._index < 3 ? this._index + 1 : 0;

    switch (this._index) {
      case 0:
        this._story.to(0);
        this._story.scene.zoomOut();
        this._zoomedIn = false;

        break;

      case 1:
        this._story.to(0);
        this._story.scene.zoomIn();
        this._zoomedIn = true;

        break;

      case 2:
        this._story.to(1);
        this._story.scene.zoomOut();
        this._zoomedIn = false;

        break;

      case 3:
        this._story.to(1);
        this._story.scene.zoomIn();
        this._zoomedIn = true;

        break;
    }

    clearTimeout(this._phaseTimer);
    this._phaseTimer = setTimeout(() => {
      this._next();
    }, 8000.0);
  }

  // ---------------------------------------------------------------------------

  // scroll(position, progress, direction, speed) {
  //   this._story.scene.scroll(position, progress, direction, speed);
  // }

  // ---------------------------------------------------------------------------

  _loader({ ft }) {}
  _update({ ft }) {
    this._touch.spring(ft * 0.5, 0.4);

    // --
    this._move();

    this._x += (this._targetX - this._x) * 0.12;
    this._y += (this._targetY - this._y) * 0.12;

    this._updateCharge();

    // --

    return {
      x: this._x,
      y: this._y,

      random: -1.0 + Math.random() * 2.0,
    };
  }

  __move() {
    this._ease *= 0.99;
    const ease = 1.0 - this._ease;

    this._targetX += (this._touch.location.x - this._targetX) * ease;
    this._targetY += (-this._touch.location.y - this._targetY) * ease;
  }

  __magnet() {
    this._targetX *= this._magnet;
    this._targetY += (0.18 - this._targetY) * (1.0 - this._magnet);
  }

  __updateCharge() {
    this._story.scene.charge(this._charge);
  }

  // ---------------------------------------------------------------------------

  _onCursorMove(e) {
    gsap.killTweensOf(this, '_magnet');
    this._magnet = 1.0;
    this._move.set('move');

    clearTimeout(this._timer);

    if (this._zoomedIn) return;

    this._timer = setTimeout(() => {
      this._ease = 1.0;

      gsap.killTweensOf(this, '_magnet');
      gsap.to(this, { _magnet: 0.9, duration: 1.5, ease: 'cubic.inOut' });

      this._move.set('magnet');
    }, 500.0);
  }

  _onCursorDown(e) {
    this._cursorDownCallback();
    this._isTouch || this._next();
    // this._updateCharge.set('on');

    // gsap.killTweensOf(this, '_charge');
    // gsap.to(this, {
    //   _charge: 1.0,
    //   duration: 0.4,
    //   ease: 'expo.in',
    //   onComplete: () => {
    //     // this._story.to(1);
    //   },
    // });
  }

  _onCursorUp(e) {
    this._cursorUpCallback();
    // this._story.to(0);

    // gsap.killTweensOf(this, '_charge');
    // gsap.to(this, {
    //   _charge: 0.0,
    //   duration: 0.7,
    //   ease: 'quint.inOut',
    //   onComplete: () => {
    //     this._updateCharge.set('off');
    //   },
    // });
  }
}
