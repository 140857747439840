<script setup>
  const signup = ref();
  const visible = isVisible(signup);

  const buttonLabel = ref();

  function onMouseEnter() {
    buttonLabel.value.show(null, () => {}, 0.0);
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="signup" class="signup" :class="{ visible }">
    <form
      @submit="
        (e) => {
          e.preventDefault();
          useSignupOverlay().signupOverlayActive.value = true;
        }
      "
    >
      <label><Scramble text="Sign up via email" :delay="0.2" /></label
      ><input type="text" placeholder="Email" v-model="useSignupOverlay().signupEmail.value" /><button
        @mouseenter="onMouseEnter"
      >
        <Scramble ref="buttonLabel" text="Submit" :delay="0.4" class="label" />
      </button>
    </form>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .signup {
    position: relative;

    width: 100%;
    height: px(4);

    &:after {
      content: '';

      position: absolute;

      width: 100%;
      height: px(1);

      left: 0;
      bottom: 0;

      background-color: $white;

      transform-origin: top left;
      transform: scale3d(0, 1, 1);
      transition: transform 0s $quadInOut;

      pointer-events: none;
    }

    &.visible {
      &:after {
        transform: scale3d(1, 1, 1);
        transition-delay: 0.2s;
        transition-duration: 0.47s;
      }

      label,
      button {
        @include blink(0.105s);
      }

      input {
        opacity: 1;
        transition-delay: 0.3s;
        transition-duration: 0.3s;
      }
    }

    form {
      position: absolute;
      width: 100%;
      height: auto;

      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      bottom: 0;

      > * {
        height: px(10);

        display: flex;
        align-items: flex-end;

        box-sizing: border-box;
        white-space: nowrap;
      }
    }

    label,
    button {
      border: px(1) solid $white;
      border-top: none;
      border-bottom: none;

      > * {
        padding-bottom: px(12);
      }
    }

    label {
      padding: 0 px(58);
    }

    input {
      width: 100%;
      height: 100%;

      padding: 0 px(28) px(14);

      opacity: 0;
      transition: opacity 0s $sineInOut;

      &::placeholder {
        color: $white-40;
      }
    }

    button {
      padding: 0 px(28);

      color: $orange;

      @include hitbox();
      &:before {
        top: -2rem;
      }
      transition: color 0.15s $sineInOut;

      @include hover {
        color: $white;
        transition-duration: 0s;

        .label {
          @include blink;
        }
      }
    }
  }
</style>
