export default (callback) => {
  onReady(() => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);

    onScroll();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onScroll);
  });

  function onScroll() {
    callback(window.scrollX, window.scrollY);
  }
};
