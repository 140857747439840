import Smoothie from '~/pinkyellow/smoothie';

// --

export const smoothie = ref('');

// --

export default (config) => {
  onMounted(() => {
    smoothie.value =
      smoothie.value ||
      new Smoothie({ ...config, container: window, content: document.body });
  });

  onBeforeUnmount(() => {
    smoothie.value?.empty();
    smoothie.value = null;
  });

  onTick((dt) => {
    smoothie.value.drink(dt);
  });
};
