import Pillow, { PillowObject } from '@pinkyellow/pillow';

import {} from 'three';
import gsap from 'gsap';

// --

export default class AnimaPrism extends PillowObject {
  // ---------------------------------------------------------------------------

  _create({ isTouch, material }) {
    Pillow().load.model('models/prism-open.glb', (model) => {
      let prism;

      model.scene.traverse((child) => {
        switch (child.name) {
          case 'insetFacesRemvedand_bridged001':
            prism = child;
            break;
        }
      });

      prism.material = material;

      this._prism = this._add(this._container(prism));
      this._prism.rotation.x = -0.1;
    });
  }

  // ---------------------------------------------------------------------------

  _update({ ft, et }, properties) {
    this._prism.rotation.y = et * 0.4;
  }

  // ---------------------------------------------------------------------------

  get orientation() {
    return this._prism.rotation.y;
  }
}
