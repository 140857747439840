<script setup>
  const store = useBaseStore();
  const items = store.getNavBar;

  // --

  const navActive = inject('navActive');

  function onCloseClick() {
    navActive.value = false;
  }

  function onSignupClick() {
    onCloseClick();
    useSignupOverlay().signupOverlayActive.value = true;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <nav class="mobile-nav">
    <CloseButton class="close" @click="onCloseClick" />

    <ul>
      <li v-for="item in items">
        <NuxtLink :to="item.page.url" @click="onCloseClick">{{ item.page.title }}</NuxtLink>
      </li>

      <!-- <li><NuxtLink to="/">PLAY</NuxtLink></li> -->
      <li><NuxtLink to="/" @click="onSignupClick">SIGN UP</NuxtLink></li>
    </ul>
  </nav>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .mobile-nav {
    position: fixed;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: $background-black;
    z-index: $layer-header;

    @include break-min-small {
      display: none !important;
    }

    .close {
      position: absolute;

      top: px(26);
      right: px(33);

      @include break-phone {
        top: px(20);
        right: px(17);
      }
    }

    ul {
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      justify-content: center;

      li {
        a {
          @include roc-grotesk(13vw);

          @include hover {
            color: $orange;
          }
        }
      }
    }
  }
</style>
