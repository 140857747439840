<script setup>
  import gsap from 'gsap';

  // --

  const store = useBaseStore();
  const items = store.getNavBar;

  const mainNav = ref();

  const playItem = ref();
  const signupItem = ref();

  function top() {
    gsap.killTweensOf(topItems);
    gsap.killTweensOf(activateItems);
    gsap.killTweensOf(deactivateItems);

    mainNav.value.forEach((item) => item.deactivate());

    gsap.delayedCall(0.95, topItems);
  }

  function activate() {
    gsap.killTweensOf(topItems);
    gsap.killTweensOf(activateItems);
    gsap.killTweensOf(deactivateItems);

    gsap.delayedCall(0.55, activateItems);
  }

  function deactivate() {
    gsap.killTweensOf(topItems);
    gsap.killTweensOf(activateItems);
    gsap.killTweensOf(deactivateItems);

    gsap.delayedCall(0.23, deactivateItems);
  }

  function topItems() {
    playItem.value.activate();
    signupItem.value.activate();
  }

  function activateItems() {
    mainNav.value.forEach((item) => item.activate());

    playItem.value.activate();
    signupItem.value.activate();
  }

  function deactivateItems() {
    mainNav.value.forEach((item) => item.deactivate());

    playItem.value.deactivate();
    signupItem.value.deactivate();
  }

  function onSignupClick() {
    useSignupOverlay().signupOverlayActive.value = true;
  }

  // --

  defineExpose({ top, activate, deactivate });
</script>

<!----------------------------------------------------------------------------->

<template>
  <nav>
    <ul>
      <li v-for="(item, index) in items">
        <NavLink ref="mainNav" :label="item.page.title" :to="item.page.url" :delay="index * 0.1" />
      </li>
      <li>
        <ul>
          <li>
            <NavButton ref="playItem" label="PLAY" button :delay="items.length * 0.1 + 0.04" disabled />
          </li>
          <li>
            <NavButton
              ref="signupItem"
              label="SIGN UP"
              button
              primary
              @click="onSignupClick"
              :delay="items.length * 0.1 + 0.18"
            />
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  nav {
    // @include roboto-mono-regular(px(16));
    letter-spacing: 0;

    user-select: none;

    ul {
      display: flex;
      align-items: center;

      gap: px(40);

      ul {
        gap: px(14);
      }
    }

    @include break-large {
      ul {
        gap: px(30);
      }
    }

    @include break-medium {
      ul {
        gap: px(24);
      }
    }
  }
</style>
