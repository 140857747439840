<script setup>
  import gsap from 'gsap';

  // --

  const { label, delay } = defineProps({
    label: String,
    delay: Number,
  });

  // --

  const text = ref();

  let isVisible = false;
  const visible = ref(false);
  const active = ref(false);

  function activate() {
    if (isVisible) return;
    isVisible = true;

    gsap.delayedCall(delay, () => (visible.value = true));
    text.value.show(null, () => (active.value = true));
  }

  function deactivate() {
    if (!isVisible) return;
    isVisible = false;

    text.value.hide();
    active.value = false;
  }

  function onMouseEnter() {
    text.value.show(null, () => {}, 0.0);
  }

  // --

  defineExpose({ activate, deactivate });
</script>

<!----------------------------------------------------------------------------->

<template>
  <NuxtLink :class="{ visible, active }" @mouseenter="onMouseEnter"
    ><Scramble ref="text" :text="label" :delay="delay" class="label"
  /></NuxtLink>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    @include hitbox();
    transition: color 0.15s $sineInOut;

    visibility: hidden;
    pointer-events: auto;

    &.visible {
      visibility: visible;
    }

    pointer-events: none;

    &.active {
      pointer-events: auto;
    }

    @include hover {
      color: $orange;
      transition-duration: 0.02s;

      .label {
        @include blink;
      }
    }
  }
</style>
