<script setup>
  import gsap from 'gsap';

  // --

  const logo = ref();
  const border = ref();

  let penrose;
  let anima;
  let list;

  let isVisible = false;

  onMounted(() => {
    penrose = logo.value.querySelector('.penrose');
    anima = logo.value.querySelector('.anima').children;
    list = logo.value.querySelector('ul').children;
  });

  function show() {
    if (isVisible) return;
    isVisible = true;

    gsap.killTweensOf(penrose);
    gsap.killTweensOf(anima);
    gsap.killTweensOf(list);
    gsap.killTweensOf(border.value.show);
    gsap.killTweensOf(blink);

    gsap.to(penrose, {
      opacity: 1.0,
      scale: 1.0,
      force3D: true,
      duration: 1.65,
      ease: 'sine.inOut',
    });
    gsap.to(anima, {
      opacity: 1.0,
      delay: 0.28,
      stagger: 0.12,
      duration: 1.17,
      ease: 'sine.inOut',
    });

    gsap.to(list, {
      opacity: 1.0,
      delay: 0.81,
      stagger: {
        each: 0.05,
        ease: 'quart.inOut',
      },
      duration: 0.42,
      ease: 'quart.inOut',
    });

    gsap.delayedCall(0.78, border.value.show);
    gsap.delayedCall(1.48, blink);
  }

  function blink() {
    for (let i = 0, j = 0; i < list.length; i += 2, j++) {
      list[i].classList.remove('blink');
      setTimeout(() => list[i].classList.add('blink'), j * 148.0);
    }
  }

  function deactivate() {
    if (!isVisible) return;
    isVisible = false;

    gsap.killTweensOf(penrose);
    gsap.to(penrose, {
      scale: 0.75,
      force3D: true,
      duration: 0.35,
      ease: 'sine.inOut',
    });

    gsap.killTweensOf(anima);
    gsap.killTweensOf(list);
    gsap.killTweensOf(border.value.show);

    border.value.hide();

    gsap.to(list, {
      opacity: 0.0,
      stagger: {
        each: 0.05,
        ease: 'quart.inOut',
      },
      duration: 0.22,
      ease: 'sine.inOut',
    });

    gsap.to(anima, {
      opacity: 0.0,
      rotateX: 10.0,
      force3D: true,
      delay: 0.05,
      stagger: 0.04,
      duration: 0.25,
      ease: 'sine.inOut',
    });
  }

  function hide() {
    deactivate();

    gsap.killTweensOf(penrose);
    gsap.to(penrose, { opacity: 0.0, duration: 0.34, ease: 'sine.inOut' });
  }

  // --

  defineExpose({ show, hide, deactivate });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="logo" ref="logo">
    <Penrose class="penrose" />
    <div>
      <IconAnima class="anima" />
      <Border thin ref="border">
        <ul ref="list">
          <li class="blink">WORLDS</li>
          <span />
          <li>BEYOND</li>
          <span />
          <li>REALITY</li>
        </ul>
      </Border>
    </div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .logo {
    @include roboto-mono-regular(px(11));
    letter-spacing: 0;

    display: flex;
    align-items: stretch;

    user-select: none;

    gap: px(15);
    .penrose {
      opacity: 0;

      transform-origin: center;
      transform: scale3d(0.88, 0.88, 1);

      pointer-events: auto;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .anima {
        :deep(path) {
          opacity: 0;
        }
      }

      ul {
        display: flex;
        justify-content: space-around;
        align-items: stretch;

        padding: 0 px(6);

        > * {
          opacity: 0;
        }

        li {
          padding: px(2) 0 px(3);
        }

        span {
          display: block;

          width: px(1);
          background-color: $white-20;
        }
      }
    }
  }
</style>
