<template>
  <svg
    width="195"
    height="20"
    viewBox="0 0 195 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8723 19.1426H27.2054L15.1942 0L12.0112 0L0 19.1426H3.09289L5.91552 14.6042L21.0496 14.6042L23.8723 19.1426ZM7.23675 12.4514L13.4526 2.44373H13.5126L19.7284 12.4514L7.23675 12.4514Z"
      fill="white"
    />
    <path
      d="M48.3984 19.1426H51.1009V2.29827H51.1309L68.5772 19.1426H71.3698V0L68.6673 0V15.477H68.6072L52.5723 0L48.3984 0V19.1426Z"
      fill="white"
    />
    <path d="M93.9821 19.1426H96.8047V0L93.9821 0V19.1426Z" fill="white" />
    <path
      d="M119.416 19.1426L122.118 19.1426V1.94917H122.178L131.667 19.1426H133.799L143.228 1.94917H143.258V19.1426L146.081 19.1426V0L141.426 0L132.808 16.0006H132.778L124.13 0L119.416 0V19.1426Z"
      fill="white"
    />
    <path
      d="M191.155 19.1426H194.488L182.477 0L179.294 0L167.283 19.1426H170.376L173.199 14.6042L188.333 14.6042L191.155 19.1426ZM174.52 12.4514L180.736 2.44373H180.796L187.011 12.4514L174.52 12.4514Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(195);
    height: px(20);
  }
</style>
