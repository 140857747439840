import Pillow, { PillowScene, PillowCamera, state } from '@pinkyellow/pillow';

import {
  PointLight,
  Mesh,
  BoxGeometry,
  MeshBasicMaterial,
  SphereGeometry,
  MeshMatcapMaterial,
  AmbientLight,
  Color,
  Fog,
} from 'three';
import gsap from 'gsap';
import { EffectPass, GodRaysEffect } from 'postprocessing';

import AnimaScene from './anima-scene';
import AnimaFractal from '../objects/anima-fractal';

// --

export default class AnimaScene02 extends AnimaScene {
  // ---------------------------------------------------------------------------

  _setupSceneEffects(properties) {
    return [(this._fractal = new AnimaFractal())];
  }

  _setupPrism() {
    return { material: new MeshBasicMaterial({ color: 0xffffff }) };
  }

  _setupScene() {
    this.fog = new Fog(0x000000, 3.0, 5.5);
    this.background = new Color(0x00ff00);
  }

  // ---------------------------------------------------------------------------

  _resizeScene(width, height) {
    // this._fractal.setSize(width, height);
  }

  // ---------------------------------------------------------------------------

  _zoomIn() {
    this._fractal.setCamera(this._camera.position.x, this._camera.position.y, 2 / 5.0);
    this._updatePosition.set('raw');

    this.fog.near = 0.5;
    this.fog.far = 2.5;

    this._fractal.zoomIn();
  }

  _zoomOut() {
    this._fractal.setCamera(this._camera.position.x, this._camera.position.y, 1.0);
    this._updatePosition.set('camera');

    this.fog.near = 3.0;
    this.fog.far = 5.5;

    this._fractal.zoomOut();
  }

  // ---------------------------------------------------------------------------

  _updateScene(time, { x, y, random }) {
    this._fractal.update(time, { rotation: this._prism.orientation, x, y });
  }
}
