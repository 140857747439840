<template>
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.974446 0L1.06888 0.0944357L7.04193 6.04391L0.998048 12.0878H3.5208L8.50229 7.10631L9.58877 6.0439L8.50229 4.9815L3.54389 6.04388e-06H0.973389L0.974446 0Z"
      fill="#FE5C00"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(10);
    height: px(13);
  }
</style>
