<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <button class="hamburger-close" aria-label="close"><IconClose /></button>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .hamburger-close {
    @include hitbox;
    @include hover {
      :deep(svg) {
        path {
          fill: $orange;
        }
      }
    }

    pointer-events: auto;
  }
</style>
