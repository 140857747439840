<script setup>
  const { maxLength } = defineProps({
    small: Boolean,
    maxLength: { type: Number, default: 4 },
  });

  const length = 3 + Math.round(Math.random() * (maxLength - 3));
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="sequence" :class="{ small }">
    <ClientOnly><Character v-for="i in length" :small="small" /></ClientOnly>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .sequence {
    display: flex;
    align-items: center;

    gap: px(3);

    &.small {
      gap: px(1.2);
    }
  }
</style>
